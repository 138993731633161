<template>
	<div class="Safty">
		
		<div class="Top" style="margin-bottom: 20px;display: flex;">
			<div class="Left">
				修改二级密码
			</div>
			<div class="Right" style="flex: 1;text-align: right;">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$Jump('/my/safty')">返回</el-button>
				</span>
			</div>
		</div>
		
		<div v-if="User == null">
			<div style="height: 300px;text-align: center;">
				用户信息获取中....
			</div>
		</div>
		
		<div class="Edit" v-if="User != null">	
		
			<li>
				<span class="Label">
					账号
				</span>
				<span class="Content">
					{{$StrStar(User.Account)}}
				</span>
			</li>
			
			<li>
				<span class="Label">
					手机号
				</span>
				<span class="Content">
					{{$StrStar(User.Mobile)}}
				</span>
			</li>
			
			<li>
				<span class="Label">
					验证码
				</span>
				<span class="Content">
					<em>
						<el-input v-model="MyTypeCode" placeholder="请输入验证码"></el-input>
					</em>
					<i>
						<el-button :disabled="btnDisabled" icon="el-icon-message" type="primary" @click="NewMobileCode()">{{btnText}}</el-button>
					</i>
				</span>
			</li>
			
			<li>
				<span class="Label">
					新二级密码
				</span>
				<span class="Content">
					<em>
						<el-input type="password" show-password v-model="NewPas1" placeholder="请输入新密码"></el-input>
					</em>
					<i>
						密码需长度超过6位
					</i>
				</span>
			</li>
			
			<li>
				<span class="Label">
					确认新二级密码
				</span>
				<span class="Content">
					<em>
						<el-input type="password" show-password v-model="NewPas2" placeholder="请再次输入新密码"></el-input>
					</em>
					
				</span>
			</li>
			
			<li>
				<span class="Label">
					
				</span>
				<span class="Content">
					<el-button type="primary" @click="SetNewPas()">保存修改</el-button>
				</span>
			</li>
		
		</div>
		

	</div>
</template>

<script>
		import md5 from 'md5';
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Radio} from 'element-ui'
	export default {
	  name: 'PasAccount',
	  props: {
	  },
	  data() {
	      return {
			  User:null,
			  Mobile:'',
			  SmsCodeId:'',//验证码验证代号
			  MyTypeCode:'',//我输入的验证码
			  NewPas1:'',
			  NewPas2:'',
			  btnDisabled:false,
			  btnText:'获取验证码'
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-radio':Radio
	  },
	  created() {
	  	this.GetUser()
	  },
	  methods:{
		  getSecond(wait){
			  let _this=this;
			  let _wait = wait;
			  if(wait == 0) {
				  this.btnDisabled=false;
				  this.btnText="获取验证码"
				  wait = _wait;
			  } else {
				  this.btnDisabled=true;
				  this.btnText="验证码(" + wait + "s)"
				  wait--;
				  setTimeout(function() {
						  _this.getSecond(wait);
					  },
					  1000);
			  }
		  },
		  SetNewPas(){
			  
			  if(this.NewPas1.length < 6 || this.NewPas2.length < 6){
				  this.$message('密码最短需6位')
				  return
			  }
			  
			  if(this.NewPas1 != this.NewPas2){
				  this.$message('两次密码不一致')
				  return
			  }
			  
		  			  let data = {
		  			  	Service:'User',
		  			  	Class: 'User',
		  			  	Action: 'EditPas2ByCode',
		  			  	Mobile:this.Mobile, //普通用户只能调取自己的信息
		  				SmsCodeId:this.SmsCodeId,
						Code:this.MyTypeCode,
						Password2: md5(this.NewPas1)
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
						this.$message(res.Msg)
		  			  	if(res.ErrorId != 0){
		  			  		return
		  			  	}
		  			  	
		  			  	this.$router.push('/my/safty')
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetUser(){
			  let data = {
			  	Service:'User',
			  	Class: 'User',
			  	Action: 'Get',
			  	Id:''//普通用户只能调取自己的信息
				
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
			  	this.User = res.Data
				this.Mobile = res.Data.Mobile
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  NewMobileCode(){
		  			  let data = {
		  				Service:'Code',
		  				Class: 'SmsCode',
		  				Action: 'NewCode',
		  				Mobile:this.Mobile,//普通用户只能调取自己的信息
						SmsAction:'reset_pas2',
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  				
						this.$message(res.Msg)
		  				if(res.ErrorId != 0){
		  					return
		  				}
		  				
		  				this.SmsCodeId = res.Data.SmsCodeId
		  				this.getSecond(60)
						
		  			  })
		  			  .catch(function (response) {
		  				this.$message('网络请求错误')
		  			  })
		  },
	  }
	}
</script>

<style scoped>
.Safty{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 40px 50px;
}
.Edit{
	border: 1px dotted rgba(0,0,0,0.15);
	padding: 30px;
	font-size: 1.1rem;
}
.Edit li{
	display: flex;
	padding: 20px 0px;
	border-bottom: 1px dotted rgba(0,0,0,0.1);
	align-items: center;
}
.Edit li .Label{
	width: 160px;
	text-align: right;
	margin-right: 20px;
	color: #999999;
}
.Edit li .Content{
	flex: 1;
	display: flex;
	align-items: center;
}
.Edit li .Content i{
	margin-left: 10px;
	color: rgba(0,0,0,0.3);
	font-style: normal;
	font-size: 0.9rem;
}
</style>
